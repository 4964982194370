import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Twitter from 'images/preventative.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'


interface Props {
  data: IndexQueryQuery
  location: Location
}

const ServicesPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const preventive_dentistry = data.preventive_dentistry?.childImageSharp?.fluid
  const restorative_dentistry = data.restorative_dentistry?.childImageSharp?.fluid
  const cosmetic_dentistry = data.cosmetic_dentistry?.childImageSharp?.fluid
  const implant_dentistry = data.implant_dentistry?.childImageSharp?.fluid
  const oral_surgery = data.oral_surgery?.childImageSharp?.fluid
  const periodontal_treatment = data.periodontal_treatment?.childImageSharp?.fluid
  const sedation_dentistry = data.sedation_dentistry?.childImageSharp?.fluid
  const root_canal_therapy = data.root_canal_therapy?.childImageSharp?.fluid
  const pediatric_dentistry = data.pediatric_dentistry?.childImageSharp?.fluid
  const family_dentistry = data.family_dentistry?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Services - The Dentists At Gateway Crossing</title>
      <meta name="description" content="TThe Dentists at Gateway Crossing" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Services - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="The Dentists at Gateway Crossing" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="Services" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Services - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="The Dentists at Gateway Crossing" />
      <meta name="twitter:image" content={Twitter} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay"></div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              The Dentists at Gateway Crossing
              </p>
              <h1 className="text-light">
              McCordsville Dental Services
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">You can find our full lineup of dental services on this page. Browse the categories below and click a service to learn more. It’s easy to schedule an appointment online. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-4">
            <Link to ="/services/preventative-dentistry/">
              <div className="columns">
                  <Img fluid={preventive_dentistry} alt="Young Woman Using a Dental Floss" />
                <h3 className ="section-1-header">Preventative Dentistry</h3>
              </div>
              </Link>
            </div>
            <div className="col-lg-4">
              <Link to ="/services/restorative-dentistry/">
              <div className="columns">
                <Img fluid={restorative_dentistry} alt="Teeth Before and After Dental Treatment." />
                <h3 className ="section-1-header">Restorative Dentistry</h3>
              </div>
              </Link>
            </div>
            <div className="col-lg-4">
            <Link to ="/services/cosmetic-dentistry/">
              <div className="columns">
                <Img fluid={cosmetic_dentistry} alt="Cosmetic Dental Treatment Result" />
                <h3 className ="section-1-header">Cosmetic Dentistry</h3>
              </div>
              </Link>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
            <Link to ="/services/implant-dentistry/">
              <div className="columns">
              <Img fluid ={implant_dentistry} alt="Dental Implant Surgery" />
                <h3 className ="section-1-header">Implant Dentistry</h3>
              </div>
              </Link>
            </div>
            <div className="col-lg-4">
            <Link to ="/services/oral-surgery/">
              <div className="columns">
              <Img fluid={oral_surgery} alt="A Young Lady Undergoing a Dental Checkup." />
                <h3 className ="section-1-header">Oral Surgery</h3>
              </div>
              </Link>
            </div>
            <div className="col-lg-4">
            <Link to ="/services/periodontal-treatment/">
              <div className="columns">
                <Img fluid={periodontal_treatment} alt="Periodontal Health" />
                <h3 className ="section-1-header">Periodontal Treatment</h3>
              </div>
            </Link>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
            <Link to ="/services/sedation-dentistry/">
              <div className="columns">
                <Img fluid={sedation_dentistry} alt="Young Woman Having A Dental Checkup" />
                <h3 className ="section-1-header">Sedation Dentistry</h3>
              </div>
              </Link>
            </div>
            <div className="col-lg-4">
            <Link to ="/services/root-canal-therapy/">
              <div className="columns">
                <Img fluid={root_canal_therapy} alt="A Woman Undergoing a Root Canal Treatment." />
                <h3 className ="section-1-header">Root Canal Therapy</h3>
              </div>
              </Link>
            </div>
            <div className="col-lg-4">
            <Link to ="/services/pediatric-dentistry/">
              <div className="columns">
                <Img fluid={pediatric_dentistry} alt="Young Girl Getting Her Dental Checkup" />
                <h3 className ="section-1-header">Pediatric Dentistry</h3>
              </div>
              </Link>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
            <Link to ="/services/family-dentistry/">
              <div className="columns">
                  <Img fluid={family_dentistry} alt="A Family Enjoying Their Day in a Park" />
                <h3 className ="section-1-header">Family Dentistry</h3>
              </div>
            </Link>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query ServicesPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    implant_dentistry: file(name: { eq: "implant-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    oral_surgery: file(name: { eq: "oral-surgery" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    periodontal_treatment: file(name: { eq: "periodontal-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    preventive_dentistry: file(name: { eq: "preventive-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    restorative_dentistry: file(name: { eq: "restorative-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cosmetic_dentistry: file(name: { eq: "cosmetic-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    sedation_dentistry: file(name: { eq: "sedation-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    root_canal_therapy: file(name: { eq: "root-canal-therapy" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pediatric_dentistry: file(name: { eq: "pediatric-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    family_dentistry: file(name: { eq: "family-dentistry" }) {
      childImageSharp {
        fluid( maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
